
<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			showMenu: false
		}
	},
	computed: {
		...mapGetters({
			'user': 'auth/user',
			'userRoles': 'auth/roles',
			'hasPermission': 'auth/hasPermission'
		})
	},

	methods: {
		checkSize() {
			if(window.matchMedia("(min-width: 768px)").matches) {
				this.showMenu = true
			} else {
				this.showMenu = false
			}
		},
		logout() {
			localStorage.removeItem('token')
			this.$router.push({name: 'Login'})
		}
	},

	mounted() {
		this.checkSize()

		window.addEventListener('resize', this.checkSize)
	}
}
</script>

<template>
    <div class="layout-full" :class="{'layout-full--show-menu': showMenu}">
		<header class="layout-full-header header">
			<div class="container-fluid header__wrap">
				<button class="btn bth-sm btn-transparent text-white mb-n2" @click="() => {showMenu = !showMenu}">
					<i class="fas fa-bars"></i>
				</button>
				<img class="header__logo" src="/img/logo.png" alt="">

				<h3 class="header__title mx-auto">INSUMOS ESTRATEGICOS</h3>

				<div v-if="user" class="ms-auto header__dropdown dropdown">
					<button class="header__dropdown-trigger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" v-if="user">
						<span v-if="user.first_name">
							{{user.first_name}} {{user.last_name}}
						</span>
						<span v-else>
							{{user.name}}
						</span>
					
					</button>
					<ul class="header__dropdown-list dropdown-menu dropdown-menu-end">
						<li>
							<router-link :to="{name: 'Profile'}">
								Perfil
							</router-link>
						</li>
						<li>
							<a href="" @click="logout">Cerrar sesión</a>
						</li>
					</ul>
				</div>
			</div>
		</header>
		<div class="layout-full-main">
			<div class="layout-full-nav" @click="() => {showMenu = false}">
				<div class="app-nav" @click.stop="">
					<div class="app-nav__header">
						<button class="btn btn-sm btn-transparent mx-2" @click="() => {showMenu = false}">
							<i class="fas fa-angle-left"></i>
						</button>
						<img class="app-nav__logo" src="/img/logo-color.png" alt="">
					</div>
					<ul>
						<li>
							<router-link :to="{name: 'Home'}" exact>
								<i class="app-nav__icon fas fa-tachometer-alt"></i> Inicio
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'access-management'}">
								<i class="app-nav__icon fas fa-lock"></i> Control de accesso
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'employees'}">
								<i class="app-nav__icon fas fa-user-circle"></i> Empleados
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'products'}">
								<i class="app-nav__icon fas fa-sticky-note"></i> Catálogo
							</router-link>
						</li>
					
						<li>
							<router-link v-if="hasPermission(['ventas', 'corporativo', 'mac', 'capacitadores','reclutadores','atusuarios'])" :to="{name: 'products-store'}">
								<i class="app-nav__icon fas fa-sticky-note"></i> Catálogo
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['ventas', 'corporativo', 'mac', 'capacitadores','reclutadores','atusuarios'])" :to="{name: 'orders-store'}">
								<i class="app-nav__icon fas fa-truck"></i> Pedidos
							</router-link>
						</li>
					
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'report-products'}">
								<i class="app-nav__icon fas fa-boxes"></i> Reporte de productos
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin', 'adminusers'])" :to="{name: 'users'}">
								<i class="app-nav__icon fas fa-users"></i> Usuarios
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'suppliers'}">
								<i class="app-nav__icon fas fa-truck"></i> Proveedores
							</router-link>
						</li>

						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'admin-orders'}">
								<i class="app-nav__icon fas fa-truck"></i> Reporte Pedidos
							</router-link>
						</li>
						<li>
							<router-link v-if="hasPermission(['admin'])" :to="{name: 'admin-agenda'}">
								<i class="app-nav__icon far fa-calendar-alt"></i> Agenda
							</router-link>
						</li>
						<!-- <li v-for="m of 20" :class="{'active' : m==3}">
							<a href="">Menu</a>
						</li> -->
					</ul>
				</div>
			</div>
			<div class="layout-full-content py-4">
				<router-view></router-view>
			</div>
		</div>
		<div>
			FOO
		</div>
	</div>
</template>

